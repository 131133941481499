import React, { useEffect } from 'react';
import { appConfig } from 'config';
import { connect } from 'react-redux';
import {
  fetchPaymentDetails,
  paymentInit,
  setCurrentPayVariant,
} from 'payment/actions';
import {
  backendSeatValidationRequestSelector,
  enhancedBasketSelector,
} from 'serviceInBasket/helpers/selectors';
import { checkSeatsSet } from 'seatingWidget/actions';
import { IStoreState } from 'types/store';
import { Bem } from 'react-bem-classes';
import PaymentForm from 'payment/components/paymentForm';
import { Text } from 'languages/components/text';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import ContactInfo from 'payment/components/contactInfoContent';
import { authOnly } from 'authorization/helpers/authOnly';
import './index.scss';
import { Loader } from 'commonBlocks/components/loader';
import { compose } from 'redux';
import { getPaymentName, isCustomerCreditCardVariant } from '../../helpers';

const mapStateToProps = (state: IStoreState) => ({
  paymentState: state.payment.payment.status,
  variants: state.payment.variants,
  currentPayVariant: state.payment.currentPayVariant,
  payment: state.payment.payment,
  basket: enhancedBasketSelector(state),
  backendSeatValidationRequest: backendSeatValidationRequestSelector(state),
  isPaxPartner: Boolean(
    state.init.paxshopAppCtx.paxPartner && appConfig.paxpartnerSalesChannel
  ),
});

const mapDispatchToProps = {
  setCurrentPayVariant,
  paymentInit,
  checkSeatsSet,
  fetchPaymentDetails,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const bem = new Bem('checkoutPage');

function CheckoutPage(props: Props) {
  const {
    backendSeatValidationRequest,
    basket,
    currentPayVariant,
    fetchPaymentDetails,
    payment,
    paymentInit,
    paymentState,
    setCurrentPayVariant,
    variants,
    isPaxPartner,
  } = props;

  useEffect(() => {
    paymentInit();
    fetchPaymentDetails();
    checkSeatsSet(backendSeatValidationRequest, -1);
  }, []);

  const showPaymentTypeSelector =
    variants.length > 0 &&
    !['IFRAME_PAYMENT', 'HTML_SNIPPET'].includes(payment.status);

  const initializing =
    basket.validationStatus === 'VALIDATING' ||
    variants.length === 0 ||
    paymentState === 'WAITING_FOR_INFO';
  const currentPaymentGatewayName =
    currentPayVariant && getPaymentName(currentPayVariant);

  if (initializing) {
    return (
      <div className={bem.element('loader')}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={bem.block()}>
      <div className="row page">
        <div className="col-md-12">
          <CurrentPageWizardHeader />
        </div>
        <div className="col-md-8 page__content">
          <div className="row col-md-12">
            <div className={bem.element('header')}>
              <h1 className={bem.element('formTitle')}>
                <Text>PAYMENT_PAGE_HEADING</Text>
              </h1>
              <div className={bem.element('amount')}>
                <div className="small">
                  <Text useSpan>PAYMENT_PAGE_AMOUNT</Text>
                </div>
                {basket.totalPrice.toFixed(2)} {basket.currency}
              </div>
            </div>
          </div>
          <PaymentForm
            variants={variants}
            currentPayVariant={currentPayVariant}
            setPayVariant={setCurrentPayVariant}
            basket={basket}
            payment={payment}
            showPaymentTypeSelector={showPaymentTypeSelector}
            submitButtonCMSKey={
              isPaxPartner && isCustomerCreditCardVariant(currentPayVariant)
                ? 'CMS_SEND_PAYMENT_LINK'
                : 'CMS_PROCEED_TO_PAYMENT'
            }
          />
        </div>

        <div className="col-md-4">
          <ContactInfo />
          <div className={`panel panel-gray ${bem.element('secureInfo')}`}>
            <div className="panel-heading clearfix">
              <div className="shield-container pull-left" />
              <i className="icon shield2" />
              <h3 className="">SSL Secure Payments</h3>
            </div>
            <div className="panel-body">
              <p>
                <strong>Your payment is secured.</strong>
              </p>
              <p>
                The card number is sent through a strongly encrypted connection
                directly to the bank.
              </p>
              <p>
                The merchant does not have access to any information about the
                card or your bank account.
              </p>
              <p>
                SSL is the industry standard and among the best software
                available today for secure commerce transactions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  authOnly(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckoutPage) as React.FC;
