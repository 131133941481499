import { combineReducers } from 'redux';
import authData from './authData';
import authSrcData from './authSrcData';
import authSiriusRedirect from './authSiriusRedirect';

export default combineReducers({
  authSiriusRedirect,
  authData,
  authSrcData,
});
