interface IQRCode {
  passengerId: number;
  qrString: string;
}

export enum TeaserShownReasons {
  NeedToBuySeats = 'NEED_TO_BUY_SEATS',
  BeforeSalesWindow = 'BEFORE_SALES_WINDOW',
  NotCheckInStation = 'NOT_CHECKIN_STATION',
}

export interface ICheckInSegment {
  checkinStatus: string;
  messageKey: string;
  segmentId: number;
  qrCodes: IQRCode[];
  restrictMobileBoardingPass: boolean;
  teaser: {
    causeShowTeaser: TeaserShownReasons | null;
    checkinCloseAt: string;
    checkinOpenAt: string;
  };
}
