import {
  containerize,
  converterSelector2,
} from '_helpers/datacontainer/selectors';
import localStorageHelper from '_helpers/localStorage';
import { basketModule } from 'basket';
import { createSelector } from 'reselect';
import { IAuthRes } from 'types/auth';
import { IPaxpartnerAuthData, IPaxReferenceData } from 'types/backendProtocol';
import { IDataContainer } from 'types/datacontainer';
import { PaxshopPageId } from 'types/routing';
import { IPaxshopReduxStore, IStoreState, PaxSelector } from 'types/store';
import { AxiosRequestConfig } from 'axios';
import { postLogin } from 'authorization/actions';

export const selectJwtToken = createSelector(
  (state: IStoreState) => state.auth.authData.data,
  (authData: IAuthRes) => (authData ? authData.jwt : null)
);

export const selectAuthData = (state: IStoreState) => {
  const { authData } = state.auth;
  return authData && authData.data;
};

export function createAxiosAuthConfig(jwt: string): AxiosRequestConfig {
  if (!jwt) {
    return null;
  }

  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
}

export const axiosConfigSelector = createSelector(
  selectJwtToken,
  createAxiosAuthConfig
);

export const authStatusSelector = converterSelector2(
  (state: IStoreState) => state.auth.authData,
  containerize((state: IStoreState) => state.languages.bookingCMSKeys),
  authData => authData
);

export function createAuthFinishPromise(
  store: IPaxshopReduxStore
): Promise<IAuthRes> {
  return new Promise<IAuthRes>((resolve, reject) => {
    function authFinished(res: IDataContainer<IAuthRes>) {
      if (res.availability.errors && res.availability.errors.length > 0) {
        reject(res.availability.errors[0]);
      } else {
        // 2024-11v-20 LH Dispatch added for Sirius redirect:
        store.dispatch(postLogin(res.data));
        resolve(res.data);
      }
    }

    let oldStatus = authStatusSelector(store.getState());
    if (!oldStatus.availability.pending) {
      authFinished(oldStatus);
      return;
    }

    const unsubscribe = store.subscribe(() => {
      const newStatus = authStatusSelector(store.getState());
      if (newStatus === oldStatus) {
        return;
      }

      oldStatus = newStatus;

      if (!newStatus.availability.pending) {
        unsubscribe();
        authFinished(newStatus);
      }
    });
  });
}

export function createSuccessAuthWatcher(
  store: IPaxshopReduxStore,
  callback: (authData: IAuthRes) => void
) {
  let oldValue = store.getState().auth.authData;

  store.subscribe(() => {
    const newValue = store.getState().auth.authData;
    if (newValue === oldValue) {
      return;
    }

    oldValue = newValue;
    const avail = newValue.availability;
    if (
      avail.pending ||
      (avail.errors && avail.errors.length > 0) ||
      !newValue.data
    ) {
      return;
    }

    callback(newValue.data);
  });
}

function remeberLoginSourceData(store: IPaxshopReduxStore) {
  createSuccessAuthWatcher(store, (res: IAuthRes) => {
    // remember initial auth info in local storage
    const srcData = store.getState().auth.authSrcData.data;
    localStorageHelper.setBookingSrcData({
      bookingNumber: res.bookingNumber,
      departureDate: res.departureDate,
      lastName: res.lastName,
    });

    basketModule.init();

    const paxPartnerData: IPaxpartnerAuthData =
      srcData.authType === 'BOOKING' ? srcData.bookingSrc : srcData.norecSrc;
    if (paxPartnerData.lastName && paxPartnerData.resellerData) {
      localStorageHelper.setPaxPartnerData({
        lastName: paxPartnerData.lastName,
        resellerData: paxPartnerData.resellerData,
      });
    } else {
      localStorageHelper.setPaxPartnerData(null);
    }

    const refData: IPaxReferenceData =
      srcData.authType === 'BOOKING'
        ? srcData.bookingSrc.referenceData
        : srcData.norecSrc.referenceData;
    if (refData) {
      localStorageHelper.setReferenceData({
        referrer: refData.referrer,
        utmCampaign: refData.utmCampaign,
        utmMedium: refData.utmMedium,
        utmSource: refData.utmSource,
      });
    } else {
      localStorageHelper.setReferenceData(null);
    }
  });
}

export const cartCurrencySelector = (state: IStoreState) =>
  state.auth.authData.data ? state.auth.authData.data.currency : null;
export const currentBookingNumberSelector = (state: IStoreState) =>
  state.auth.authData.data
    ? state.auth.authData.data.booking.bookingNumber
    : null;
export const currentBookingDepartureDateSelector = (state: IStoreState) =>
  state.auth.authData.data ? state.auth.authData.data.departureDate : null;
export const currentBookingId = (state: IStoreState) =>
  state.auth.authData.data ? state.auth.authData.data.bookingId : null;

export const isLoggedInSelector: PaxSelector<boolean> = (state: IStoreState) =>
  state.auth.authData.data !== null;

export const homePageIdSelector: PaxSelector<PaxshopPageId> = (
  state: IStoreState
) => (state.init.paxshopInitParams.myBookingAsIcon ? 'SEATING' : 'MY_BOOKING');

export const authFieldsSelector = (
  state: IStoreState
): { [key: string]: boolean } =>
  state.init.paxshopInitParams.authFields.reduce((res, key: string) => {
    res[key] = true;
    return res;
  }, {});

export const selectIsBookingNumberMandatory = (state: IStoreState) =>
  state.init.paxshopData.data.mandatoryBookingNumber;

export function selectIsAutoAllocationAllowed(state: IStoreState) {
  return state.auth.authData.data.autoAllocationAllowed;
}

export function initialize(store: IPaxshopReduxStore) {
  remeberLoginSourceData(store);
}
