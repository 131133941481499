import { IEnhancedBasket, IServiceInBasket } from 'types/services';
import { IPaymentVariant } from './types';

export function compilePurchasesForBackend(basket: IEnhancedBasket) {
  const groupedByPassenger: {
    [passengerId: number]: {
      quantity: number;
      serviceId: number | string;
      airPathDirection: 'IN' | 'OUT';
    }[];
  } = {};

  basket.serviceInstances.forEach(instance => {
    const serviceItem = {
      quantity: instance.quantity,
      serviceId: instance.serviceId,
      airPathDirection: instance.direction as 'IN' | 'OUT',
      seatNumber: instance.seatNumber,
      supplier: instance.supplier,
      serviceDetails: instance.serviceDetails,
    };

    groupedByPassenger[instance.passengerId] =
      groupedByPassenger[instance.passengerId] === undefined
        ? [serviceItem]
        : [...groupedByPassenger[instance.passengerId], serviceItem];
  });

  return Object.entries(groupedByPassenger).map(
    ([passengerId, boughtPassengerServices]) => ({
      passengerId: Number(passengerId),
      boughtPassengerServices,
    })
  );
}

export function compileBasketForServiceContext(basket: IServiceInBasket[]) {
  return basket.reduce((perPassenger, item) => {
    let bucket = perPassenger.find(pp => pp.passengerId === item.passengerId);
    if (!bucket) {
      bucket = { passengerId: item.passengerId, boughtPassengerServices: [] };
      perPassenger.push(bucket);
    }

    const purchaseItem: any = {
      quantity: item.quantity,
      serviceId: item.serviceId,
      airPathDirection: item.direction,
    };

    if (item.seatNumber) {
      purchaseItem.seatNumber = item.seatNumber;
    }

    bucket.boughtPassengerServices.push(purchaseItem);

    return perPassenger;
  }, []);
}

export function isKlarnaVariant(variant: IPaymentVariant) {
  return (
    variant &&
    (variant.name === 'klarna' ||
      variant.name === 'klarna_creditcard_customer' ||
      variant.name === 'klarna_creditcard_reseller')
  );
}

export function isNetaxeptVariant(variant: IPaymentVariant) {
  return (
    variant &&
    (variant.name === 'netaxept' ||
      variant.name === 'netaxept_credit_card_customer' ||
      variant.name === 'netaxept_credit_card_reseller')
  );
}

export function isCustomerCreditCardVariant(variant: IPaymentVariant) {
  return (
    variant &&
    (variant.name === 'netaxept_credit_card_customer' ||
      variant.name === 'klarna_creditcard_customer')
  );
}

export function getPaymentName(variant: IPaymentVariant) {
  if (isKlarnaVariant(variant)) {
    return 'Klarna';
  }
  return isNetaxeptVariant(variant) ? 'NETS' : 'DIBS';
}
